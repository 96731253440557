<template>
  <div  style="margin: -24px -24px 0;">
    <div class="page-header" :style="{paddingLeft: isCollapse ? '80px' : '256px'}">
      <BreadCrumb />
      <div class="page-header-detail">
        <slot name="page-header-detail"></slot>
      </div>
    </div>
    <div id="page-content" class="page-content">
        <slot />
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb"
import { mapGetters } from 'vuex'

export default {
  components: {
    BreadCrumb
  },
  computed: {
    ...mapGetters(['isCollapse','userInfo'])
  },
}
</script>

<style lang="stylus">
.page-header
  background-color #fff
  height 50px
  margin-left 20px
  border-bottom 1px solid #e8e8e8
  position fixed
  top 64px
  left 0
  width 100%
  z-index 99
  display flex
  align-items center
  box-shadow 0 1px 5px rgba(0 0 0 .05)

.page-content
  margin 24px 24px 0
  padding-top 50px
.main-page-content
  min-height calc(100vh - 64px - 60px - 65px)
</style>

